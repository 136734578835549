@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @apply bg-zinc-800;
  @apply h-screen;
}

#root {
  @apply h-full;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

th,
h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-normal;
}

.material {
  font-family: "Material Symbols Outlined";
  font-size: 1.5em;
  font-variation-settings:
    "FILL" 1,
    "wght" 400,
    "GRAD" 0,
    "opsz" 24;
}

.material-inline {
  display: block-inline;
  vertical-align: -0.115em;
  font-size: 1em;
}
